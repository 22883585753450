class AmeMh extends HTMLElement {
    
    constructor() {
        super();
    }

    connectedCallback() {
        this.config();

        //added class on selector-wrapper
        document.querySelector(this.selectorWrapper).classList.add('mh2021Page');
    }

    config() {
        //css
        let style = document.createElement("style");
        style.textContent = this.style();
        this.appendChild(style);
        
        //appendo il markup
        this.insertAdjacentHTML('beforeend', this.template())

        //js
        this.js();
    }

    template() {
        let template = `
            <div id="mh2021" class="mhInView">
                <div id="adv_LdbMastheadPush" class="mh2021Strip adv_concessionaria adv-desktop"></div>
            </div>
            <div id="mh2021Fake"></div>
            <div id="bottomStrip2021">
                <button class="adv-close"></button>
                <div id="adv_Anchorad" class="adv_concessionaria adv-mobile adv-disabled"></div>
            </div>
        `;
        return template;
    }

    style() {

        const css = `
            :root {
                --altezzaMh2021: 33vw;
                --bgMh2021: #fff;
            }
            #mh2021 {
                width: 100vw;
                position: fixed;
                top: 0;
                left: 0;
                overflow: hidden;
                background: ${(this.bgMh) ? this.bgMh : 'var(--bgMh2021)'};
            }
            #mh2021.mhForeground {
                z-index: 9999;
                pointer-events: none;
            }
            #mh2021Fake {
                height: var(--altezzaMh2021);
                width: 90vw;
                position: absolute;
                top: 0;
                left: 5vw;
                overflow: hidden;
                z-index: -100;
            }
            .mh2021Page {
                transition: margin 300ms ease-in-out;
                position: relative;
                z-index: 1;
            }
            .mh2021Strip {
                width: 100vw;
                max-height: var(--altezzaMh2021);
                overflow: hidden;
                background: ${(this.bgMh) ? this.bgMh : 'var(--bgMh2021)'};
                display: flex;
                justify-content: center;
                align-items: flex-start;
                pointer-events: all;
            }
            #bottomStrip2021 {
                position: fixed;
                z-index: 100;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                background: #FFF;
                box-shadow: 0 0 4px rgb(33 33 33 / 20%);
                transition: all .5s;
                transform: translateY(85px);
            }
            #bottomStrip2021 .adv-close {
                position: absolute;
                width: 28px;
                height: 28px;
                top: -28px;
                right: 0;
                background: #fff url() no-repeat 9px;
                background-image: url('data:image/svg+xml;charset=utf-8,<svg width="13" height="13" viewBox="341 8 13 13" xmlns="http://www.w3.org/2000/svg"><path fill="%234F4F4F" d="M354 9.31 352.69 8l-5.19 5.19L342.31 8 341 9.31l5.19 5.19-5.19 5.19 1.31 1.31 5.19-5.19 5.19 5.19 1.31-1.31-5.19-5.19z" fill-rule="evenodd"/></svg>');
                background-size: 13px 13px;
                box-shadow: 0 -1px 1px 0 rgb(33 33 33 / 20%);
                border-radius: 12px 0 0;
                transform: translateY(85px);
                border: none;
            }
            #bottomStrip2021.adv-loaded,
            #bottomStrip2021.adv-loaded .adv-close {
                transform: translateY(0);
            }
            @media all and (min-width: 1000px) {
                :root {
                    --altezzaMh2021 : 250px;
                }
                .mh2021Strip {
                    background: ${(this.bgMh) ? this.bgMh : 'var(--bgMh2021)'};
                }
            }
        `;

        return css;
    }

    js() {
        window.googletag = window.googletag || { cmd: [] };

        if (this.disableStrip != "true" && !document.querySelector('.no-strip-mobile') && window.innerWidth <= 768 ) {
            let fallback = () => {
                setTimeout(() => {  
                    //fallback solo se non c'è una flyingpush che sta girando
                    if (document.getElementById('flyfirst-mobile-placement')) {
          
                        if(document.getElementById('flyfirst-mobile-placement').childNodes.length == 0) {
                            this.startAncorad("fallback timer");
                        } else {
                            window.addEventListener("callAncorAd", () => this.startAncorad("closeFlyfirst") );
                            console.log('[mh2021] strip mobile - fallback fallita per presenza flyfirst. Aggiungo ascolto evento chiusura.')
                        }
                    } else {
                        this.startAncorad("fallback timer");
                    }
                }, 5000);
            };

            postTcfReady(fallback);
        }
    }

    get selectorWrapper() {
        return this.getAttribute('selector-wrapper');
    }
    
    get bgMh() {
        return this.getAttribute('bg-mh');
    }

    get disableStrip() {
        return this.getAttribute('disable-strip');
    }


    //strip_animation start
    static strip_animation() {
        const logMh = (msg, val) => {
            if (localStorage.getItem("mh2021Debug"))
            console.log("[mh2021] - " + msg, val);
        }

        let ameMhElement        = document.querySelector('ame-mh'),
            getEnableEvents     = ameMhElement.getAttribute('enable-events'),
            getDisableStrip     = ameMhElement.getAttribute('disable-strip'),
            getViewTimeLimit    = Number(ameMhElement.getAttribute('view-time-limit')),
            getFirstDelay       = Number(ameMhElement.getAttribute('first-delay'));
            
        let debug = 1,
            viewTimeLimit = (getViewTimeLimit && getViewTimeLimit != "") ? getViewTimeLimit : 5000,
            firstDelay = (getFirstDelay && getFirstDelay != "") ? getFirstDelay : 3000;

        let mh = document.getElementById("mh2021"),
            mhFake = document.getElementById("mh2021Fake"),
            page = document.querySelector('.mh2021Page'),
            strip = document.querySelector(".mh2021Strip"),
            paddingStrip = document.querySelector("#padding-strip"),
            flyFirstMobile = document.getElementById('flyfirst-mobile-placement'),
            mhIntersection,
            pageSpacedInTop = 0,
            stripH = strip.offsetHeight || 0;

        if (debug == 1) localStorage.setItem("mh2021Debug", 1);

        if (stripH == 0) {
            logMh("Strip ad altezza 0 -> return false");
            return false;
        }

        if (typeof window.stripanimationrun !== "undefined") {
            console.warn(
                "[mh2021] - ATTENZIONE! Strip animation richiamata più volte!"
            );
            return false;
        }
        window.stripanimationrun = 1;

        if (getEnableEvents == "true") {
            if (window.gtag) {
                gtag("event", "Start", {
                    event_category: "mh2021",
                    event_label: document.location.href,
                    non_interaction: true,
                });
            } else if (window.ga) {
                ga('send', 'event', "mh2021", "Start", document.location.href, {
                    nonInteraction: true
                });
            } else {}
        }

        let mh2021PageInTopView = (motivo) => {
            logMh("mh2021PageInTopView", motivo); //log del motivo per cui do margine superiore alla pagina
            observer.disconnect();
            let mhH = mh.firstElementChild.offsetHeight;
            if(mhH <= 50) mhH = 0; //fix per webview fb. Per qualche motivo gira la strip_animation sulla 3x1. Impediamo di mettere margine alla pagina se non c'è uno slot consistente e 50px dovrebbero essere safe.
            let padding = 0
            // se c'è il blocco di padding aggiungo anche quello all'altezza
            if (paddingStrip) padding = paddingStrip.offsetHeight;
            page.style.marginTop = mhH + padding + "px";
            logMh("mhH:", mhH);
            pageSpacedInTop = 1;

            if (getEnableEvents == "true") {
                if (window.gtag) {
                    gtag("event", motivo, {
                        event_category: "mh2021",
                        event_label: document.location.href,
                        non_interaction: true,
                    });
                } else if (window.ga) {
                    ga('send', 'event', "mh2021", motivo, document.location.href, {
                        nonInteraction: true
                    });
                } else {}
            }
            
            /*document.body.removeEventListener('click',mh2021PageInTopView); //DA RIABILITARE IN CUI SI RIATTIVI L'INTERAZIONE UTENTE
            document.body.removeEventListener('keypress',mh2021PageInTopView);*/
        };

        //check iniziale
        setTimeout(() => {
            if (mhIntersection) {
                logMh('top view');
                mh2021PageInTopView("In top view all'inizio");
            }
        }, firstDelay + 100);

        //fix
        setTimeout(() => {
            logMh("Fix inizio", performance.now());
            mh.classList.add("mhForeground");
            if (paddingStrip) {
                let mhH = mh.firstElementChild.offsetHeight;
                paddingStrip.style.setProperty('--margin-top-adv', mhH + 'px');
            }

        }, firstDelay + 400);

        //defix
        setTimeout(() => {
            logMh("Defix", performance.now());
            mh.classList.remove("mhForeground");
        }, firstDelay + viewTimeLimit + 400);

        //callback observer
        let cbMh = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (pageSpacedInTop == 0 && mhIntersection == false) {
                        mh2021PageInTopView("Ritorno in top view");
                    }
                    mhIntersection = true;
                } else {
                    mhIntersection = false;
                }
            });
        };

        //opzioni observer
        let optObs = {
            rootMargin: "0px",
            threshold: 1,
        };

        //dichiarazione observer
        let observer = new IntersectionObserver(cbMh, optObs);

        //start observer
        logMh("Start observer", "");
        observer.observe(mhFake);
    }
    // Eventi propagati dal video a cui eventualmente legarsi per mostrarlo post chiusura: flight_closed | flight_auto_closed (parte sempre anche in caso di chiusura volontaria)
    
    startAncorad (initiator) {
        if (
            typeof startAncoradCheck !== 'undefined' 
            || window.innerWidth > 768 
            || this.getDisableStrip === "true" 
            || document.querySelector('.no-strip-mobile')
        ) {
            console.log('[mh2021] strip mobile inabilitato');
            return false;
        }

        console.log('[mh2021] strip mobile -  initiator', initiator)
        
        window.removeEventListener("callAncorAd", () => this.startAncorad("closeFlyfirst") );
        window.startAncoradCheck = 1;
        document.querySelector("#adv_Anchorad").classList.remove('adv-disabled');
        
        if(typeof apstag === "object") {
            PMaddNewBid({
                slot: {name: "Anchorad"},
                size: [[320,100],[300,100],[320,50],[300,50]]
            });
        } else {
            googletag.cmd.push(function(){
                setPiemmeSlot({name: "Anchorad"}, [[320,100],[300,100],[320,50],[300,50]], "addApsSlot");
                googletag.display("adv_Anchorad")
            });
        }
    }
}
customElements.define("ame-mh", AmeMh);